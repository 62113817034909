<template>
  <a-modal v-model:open="open" :centered="true"  width="500px" :bodyStyle="bodyStyle"  :footer="null" :closable="false">
    <div class="pay_deposit_block font_subtitle">
      {{$t('banedTxt')}}
    </div>
    <a-button class="button">customersupport@falcons.com</a-button>
  </a-modal>

</template>

<script setup>
import { ref, defineExpose } from 'vue';
const open = ref(false)
const bodyStyle = ref({
  padding: '28px 56px',
  borderRadius: '0',
  zIndex:999
})
// import { useRouter } from 'vue-router'
// const router = useRouter()
// const jumpTo = () => {
//   open.value = false
//   router.push('/contactUs')
// }
defineExpose({
  open,
})
</script>

<style scoped>
.pay_deposit_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:8px;
}
.pay_deposit_block #paypal-button-container{
  width: 100%;
}
.button{
  width: 100%;
  height: 40px;
  /* border-radius: 32px; */
  font-weight: 500;
  margin-top: 16px;
  cursor: default;
}
</style>