import { Web3AuthNoModal } from "@web3auth/no-modal";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
const clientId = 'BHpZtlrcqBZJus7kxCthKCWyKKFLCSo3Dsab0ckG5rgAO2WRq-XdsBMFukEvuivn0pEgwouFA8bd4gw4Oyz5f7E'
// const clientId = 'BAglbUsRXdZ0h6mnZy9Oox931Dz4WtrUSqkB7f2zOHVsOsAWBn_FIigJ6CccuV2ptKB2Agtm-3caG2gimNmLFzg'
const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0xaa36a7", // Please use 0x1 for Mainnet, 11155111(0xaa36a7) for Sepolia Testnet
    rpcTarget: "https://rpc.ankr.com/eth_sepolia",
    // rpcTarget: `${process.env.VUE_APP_API_URL}/webapi/ankr/getDigitalAssets`,
    displayName: "Sepolia devnet",
    // blockExplorerUrl: "https://sepolia.etherscan.io/",
    ticker: "ETH",
    tickerName: "Ethereum",
    // logo: "https://openlogin.com/images/ethereum.png",
  };
 
  const web3auth = new Web3AuthNoModal({
      clientId,
      web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
      chainConfig,
  });

  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

    const openloginAdapter = new OpenloginAdapter({
      privateKeyProvider,
      adapterSettings: {
        clientId,
        network: 'sapphire_devnet',
        uxMode: 'popup',
      }
    });
    web3auth.configureAdapter(openloginAdapter);

 export default web3auth