<template>
  <a-modal v-model:open="open" :centered="true"  width="400px" :bodyStyle="bodyStyle"  :footer="null" :closable="false">
    <div class="pay_deposit_block font_subtitle">
      {{$t('mobileTxt')}}
    </div>
    <div class="app_block">
      <img src="@/assets/images/app_download.svg" alt="apple">
      <img src="@/assets/images/app_google.svg" alt="google">
    </div>
    <a-button class="button" @click="close">{{$t('Continuewithbrowser')}}</a-button>
  </a-modal>
</template>
<script setup>
import { ref, defineExpose } from 'vue';
const open = ref(false)
const bodyStyle = ref({
  borderRadius: '0',
  zIndex:999
})
const close = () => {
  open.value = false
}
defineExpose({
  open,
})
</script>
<style scoped>
.pay_deposit_block {
  text-align: center;
}
.pay_deposit_block #paypal-button-container{
  width: 100%;
}
.app_block{
  display: flex;
  gap: 8px;
  justify-content: space-around;
  padding:20px 0;
}
.button{
  width: 100%;
  height: 40px;
  /* border-radius: 32px; */
  font-weight: 500;
  margin-top: 16px;
}
</style>