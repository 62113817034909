const ar = {
  auctionLots: "جميع القِطع",
  auctionTimepieces: "الساعات",
  auctionHandbags: "حقائب اليد",
  auctionCars: "السيارات",
  auctionLotlh: "رقم القِطعة (من الأدنى إلى الأعلى)",
  auctionLothl: "رقم القِطعة (من الأعلى إلى الأدنى)",
  auctionReslh: "الحد الأدنى للسعر (من الأدنى إلى الأعلى)",
  auctionReshl: "الحد الأدنى للسعر (من الأعلى إلى الأدنى)",
  auctionEstimate: "السعر التقديري",
  auctionReserve: "الحد الأدنى للسعر",
  auctionLot: "القطعة",
  auctionTitle: "اكتشفوا المزيد",
  carTips: "عرض المنتج غير مسموح بالمزاد",
  shyneInfo:
    "فالكونز تتعاون مع شاين في جلسة مباشرة تُبث حول العالم لفتح علب بطاقات التداول الرياضية في برج العرب المرموق. انضموا إلينا في جيلت بار لهذه الجلسة الخاصة، بعد مزادنا الحصري في 28 سبتمبر.",
  shyneLiveInfoOne: "هنا تتجلَّى أساطير فتح علب بطاقات التداول الرياضية.",
  shyneLiveInfoTwo: "لا تفوتوا لحظات السحب التي ستغير اللعبة.",
  liveOne: "العُلب تُفتح",
  liveTwo: "مباشرة",
  liveThree: "الآن",
};
const en = {
  auctionLots: "All Lots",
  auctionTimepieces: "Timepieces",
  auctionHandbags: "Handbags",
  auctionCars: "Automobiles",
  auctionLotlh: "Lot Number (low to high)",
  auctionLothl: "Lot Number (high to low)",
  auctionReslh: "Reserve (low to high)",
  auctionReshl: "Reserve (high to low)",
  auctionEstimate: "ESTIMATE",
  auctionReserve: "RESERVE",
  auctionLot: "LOT",
  auctionTitle: "Discover more lots",
  carTips: "Product make an offer not allowed for auction",
  shyneInfo:
    "Falcons and Shyne are partnering for a globally streamed live sports card break at the prestigious Burj Al Arab. Join us at the Gilt Bar for this special breaking session, following our exclusive auction on September 28.",
  shyneLiveInfoOne: "This is where the next breaking legends are made.",
  shyneLiveInfoTwo: "Don’t miss the pulls that will change the game.",
  liveOne: "UNBOXING",
  liveTwo: "LIVE",
  liveThree: "NOW",
};

export { ar, en };
