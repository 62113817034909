export function newNumber(value,currency = 'USD'){
    if(!value){
        return 0.00
    }

    value = Math.round(value/100);
    const newPrice =  value.toLocaleString('en-US', { style: 'decimal' });
    return newPrice + ' ' + currency



    // value = value / 100
    // let isPositive = value > 0
    // const value2Array = value.toString().split('.')
    // if(value2Array.length === 2) {
    //     //有小数点，直接四舍五入
    //     value = Math.round(value * 100) / 100
    // }
    // const intPart = Math.trunc(value)
    // const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')

    // return (isPositive ? '' : '-') + intPartFormat + ' ' + currency
}