const ar = {
  sellStep: "الخطوة",
  sellStepName: "وضع البيع وموقع القطعة",
  sellTitle: "يرجى مراجعة جميع التفاصيل",
  sellItemLocation: "موقع القطعة",
  sellSellingMode: "وضع البيع",
  sellEditLocation: "تعديل الموقع والوضع",
  sellGender: "الجنس",
  sellBrand: "العلامة التجارية",
  sellsProductType: 'الفئة',
  sellAccessories: "الملحقات",
  sellOriginalPackaging: "التغليف الأصلي",
  sellCondition: "الحالة",
  sellOtherInclusions: "مرفقات أخرى",
  sellDescription: "الوصف",
  sellProofofAuthenticity: "إثبات الأصالة",
  sellReceipts: "إثبات الأصالة",
  sellCertificate: "الشهادة",
  sellSerialNumber: "الرقم التسلسلي",
  sellEditItemDetails: "تعديل تفاصيل القطعة",
  sellEditPhotos: "تعديل الصور",
  sellTipsTitle: "شكراً لك على تقديم قِطعتك",
  sellTipsInfo:
    "لتقديم تثمينٍ دقيق، سيقوم فريقنا بمراجعة قِطعتك والتحقُّق من أصالتها وتصنيفها بناءً على المعلومات المقدَّمة في الطلب. تحتفظ فالكونز بالحقِّ الحصريِّ في تحديد سعر قطعتك بناءً على المعلومات التفصيلية.",
  sellBackHome: "العودة للصفحة الرئيسة",
  sellAddItem: "إضافة قطعة أخرى",
  sellStep1: "وضع البيع وموقع القطعة",
  sellStep2: "تفاصيل القِطعة",
  sellStep3: "إثبات الأصالة",
  sellStep4: "حمِّل الصور",
  sellItemStep1: "التثمين الأوَّلي",
  sellItemStep2: "تفاصيل الشحن",
  sellItemStep3: "إدراج القطعة والربح",
};
const en = {
  sellStep: "STEP",
  sellStepName: "Proof of Authenticity",
  sellTitle: "PLEASE REVIEW ALL DETAILS",
  sellItemLocation: "Item location",
  sellSellingMode: "Selling mode",
  sellEditLocation: "Edit Location & Mode",
  sellGender: "Gender",
  sellBrand: "Brand",
  sellsProductType: "Category",
  sellAccessories: "Accessories",
  sellOriginalPackaging: "Original Packaging",
  sellCondition: "Condition",
  sellOtherInclusions: "Other Inclusions",
  sellDescription: "Description",
  sellProofofAuthenticity: "Proof of Authenticity",
  sellReceipts: "Receipts",
  sellCertificate: "Certificate",
  sellSerialNumber: "Serial number",
  sellEditItemDetails: "Edit Item Details",
  sellEditPhotos: "Edit Photos",
  sellTipsTitle: "THANK YOU FOR SUBMITTING YOUR PIECE",
  sellTipsInfo:
    "Our team will review, authenticate, and grade your submission based on the provided information. Falcons retains the exclusive right to determine the price of your piece based on the detailed information.",
  sellBackHome: "Back to homepage",
  sellAddItem: "Add another item",
  sellStep1: "Location & Mode",
  sellStep2: "Item Details",
  sellStep3: "Proof of Authenticity",
  sellStep4: "Upload your Photos",
  sellItemStep1: "Preliminary Quotations",
  sellItemStep2: "Shipping Details",
  sellItemStep3: "Listing & Earn",
};

export { ar, en };
