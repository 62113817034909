/*
 * @Author: yangpeng
 * @Date: 2024-08-28 20:39:11
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-09-05 21:12:50
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\sellUpload.js
 * @Description:
 */
const ar = {
	uploadTip: 'لتحميل الصورة، يُرجى النقر هنا أو استخدام خاصية السحب والإفلات',
	sellUpload1: 'تنسيقات الصور:',
	sellUpload2: 'تنسيقات المستندات:',
	sellUpload3: 'الحد الأقصى لحجم الملف:',
	sellUpload4: '10 ميغابايت',
	Certificates: 'الشهادات',
	sellCerDes: 'قم بتضمين شهادات الأصالة، إن وُجدت.',
	Receipts: 'الإيصالات',
	sellRecDes: '.قم بتضمين الإيصالات، إن وُجدت',
	Serial: 'الأرقام التسلسلية',
	sellSerDes:
		'تأكَّد من أنَّ الأرقام التسلسلية أو علامات التعريف مرئية في الصور.',
	sellMind: 'يُرجي أن تضع في اعتبارك ما يلي:',
	sellTipKey1: '',
	sellTipVal1: '',
	sellTipKey2: '',
	sellTipVal2: '',
	sellTipKey3: '',
	sellTipVal3: '',
	sellPhoto1: 'الجهة الأمامية',
	sellPhoto2: 'الجهة الخلفية',
	sellPhoto3: 'الجهة السفلية',
	sellPhoto4: 'الجهة العلوية',
	sellPhoto5: 'من الداخل',
	sellPhoto6: 'مرفقات أخرى',
};
const en = {
	uploadTip: 'To upload an image please click here or use the drag and drop',
	sellUpload1: 'Image formats:',
	sellUpload2: 'Document formats:',
	sellUpload3: 'File Size Limit:',
	sellUpload4: '10 MB',
	Certificates: 'Certificates',
	sellCerDes: 'Include certificates of authenticity, if available.',
	Receipts: 'Receipts',
	sellRecDes: 'Provide original purchase receipts or proof of purchase.',
	Serial: 'Serial Numbers',
	sellSerDes:
		'Ensure that any serial numbers or identifying marks are visible in the photos.',
	sellMind: 'To Keep in mind:',
	sellTipKey1: 'Close-Up Shots:',
	sellTipVal1:
		'Include close-up shots of tags, serial numbers, and any imperfections.',
	sellTipKey2: 'High-Quality Photos:',
	sellTipVal2:
		'Upload high-resolution images from multiple angles (front, back, sides & interior).',
	sellTipKey3: 'White Background:',
	sellTipVal3: 'All pictures must be taken on a white background.',
	sellPhoto1: 'Front view',
	sellPhoto2: 'Back View',
	sellPhoto3: 'Bottom View',
	sellPhoto4: 'Top View',
	sellPhoto5: 'Inside',
	sellPhoto6: 'Other Inclusions',
};
export { ar, en };
