import birdEn from './birdEn'
import { en as landingEn } from './landingpage';
import { en as sconceptEn } from './sconcept';
import { en as instructionBookEn } from './instructionBook';
import { en as sellEn } from './sell'
import { en as detailsAndetcEn } from './detailsAndetc';
import { en as auctionEn } from './auction';
import { en as sellUpload } from './sellUpload';
const en = {
    ...sconceptEn,
    ...landingEn,
    ...birdEn,
    ...instructionBookEn,
    ...detailsAndetcEn,
    ...sellEn,
    ...auctionEn,
    ...sellUpload,
    signIn: 'Sign in',
    WorldofFalcons:'World of Falcons',
    Cart: 'Cart',
    Collections: 'Collections',
    Downloadapp: 'Download App',
    login: 'Login',
    saleLeft:'END OF YEAR',
    saleRight:'MEGA SALE',
    register: 'Register',
    logout: 'Log out',
    Signup: 'Sign up',
    DiscoverFalcons:'DISCOVER FALCONS',
    newDiscoverFalcons:'DISCOVER FALCONS',
    ContinueAsGuest: 'Back to home page',
    SignUpIn: 'Sign in / Register',
    SignUpAgree: 'By signing up you agree to our ',
    TermsOfService: 'Terms & Conditions',
    TAndC: 'T & C',
    Policy: 'Privacy Policy',
    Email: 'Email',
    Password: 'Password',
    invitationCodePlaceholder: 'Invitation code (optional)',
    EmailAddress: 'Email Address',
    SendResendLink: 'Send Reset Link',
    GoToHomePage: 'Go to Home page',
    NewPassword: 'New Password',
    SavePassword: 'Save Password',
    passwordRule: 'Password must be at least 8 characters and contain both alphabetic and numeric characters.',
    confirmPasswordRule: 'Please confirm the new password is correct',
    ConfirmPassword: 'Confirm Password',
    VerificationCode: 'Verification code',
    SendToYourEmail: 'It has been sent to your email',
    DoItLater: 'Complete Later',
    CompleteMyProfile: 'Complete my profile',
    HasAccount: 'Already have an account? ',
    emailRule: 'Please enter a valid email address.',
    emailRequired: 'Invalid email',
    passwordRequired: 'Invalid password',
    rememberRule: 'Remember me rule',
    signupRule: 'To register an account, you should agree to the Terms & Conditions of Falcons',
    ValidateName: 'Please enter a valid name.',
    LogIn: 'Log in',
    AuctionwithFalcons:'Auction with Falcons',
    Asymphony:'A symphony of prestige and bidding excellence.',
    TYPESOFAUCTIONSATFALCONS:'TYPES OF AUCTIONS AT FALCONS',
    Online:'Online',
    LogInGoogle: 'Log in with Google',
    NoAccount: "Don't have an account yet? ",
    remember: 'Keep me signed in',
    CurrentPassword: 'Current Password',
    ForgotPassword: 'Forgot Password?',
    ForgotYourPassword: 'Forgot your password?',
    SetNewPassword: 'Set new password',
    NoWorryToResetPassword: "There is nothing to worry about, we'll send you a message to help you reset your password.",
    PasswordChangeSentEmail: 'Password reset link sent to your email',
    ConfirmNewPassword: 'New password has been successfully set',
    GoToLogInPage: 'Go to log in page',
    usa: 'United States USD',
    Searchfor: 'Search for',
    searchBrands: 'Search brands',
    Subscribe: 'Subscribe',
    getEmail: 'Enter your email address to get the latest news',
    Sellitem: 'Sell Now',
    Neverused: 'Never used',
    Viewmore: 'View more',
    buy: 'Buy',
    Buynow: 'Buy Now',
    Auctions: 'Auctions',
    Remove: 'Remove',
    PriceDetails: 'Price details',
    ContinueToCheckout: 'Continue to checkout',
    Checkout: 'Checkout',
    TotalPayable: 'Total Payable',
    Total: 'Total',
    Edit:'Edit',
    AllInclusive:'All Inclusive ',
    Included:'Included',
    WelcomeFalcons:'WELCOMEFALCONS',
    CommissionFee: 'Commission fee',
    PromoCode:'Promo Code',
    shippingInsurance: 'shipping & insurance',
    liveDesc:'This is where the next breaking legends are made. Don’t miss the pulls that will change the game.',
    Commission: 'Commission',
    liveStreaming:'SPORTS BREAK',
    liveTag:'LIVE',
    BackToAuction: 'Back to auction',
    TimeForPayment: 'Time for payment',
    Startselling: 'Start selling',
    sellVerifyTitle: 'VERIFICATION REQUIRED',
    sellVerifyContent1: 'To ensure the highest standards of authenticity and quality, ',
    sellVerifyContent2: 'we require additional verification.',
    sellVerifiyContinue: 'CONTINUE TO VERIFICATION',
    SellwithFalcons: 'Sell with Falcons',
    Locationofitem: 'Location of item',
    Sellingmode: 'Selling Mode',
    Details: 'Details',
    Photos: 'Photos',
    Review: 'Review',
    Continue: 'CONTINUE',
    Back: 'BACK',
    Overview: 'Overview',
    VIEWPRODUCTPAGE:'VIEW PRODUCT PAGE',
    WAITFORBIDSTATUS:'WAIT FOR BID STATUS',
    BIDANDMAKEANOFFER:'BID AND MAKE AN OFFER',
    SellingmodeLocationofitem: 'Location and Mode',
    Gender: 'Gender',
    Category: 'Category',
    Brand: 'Brand',
    Condition: 'Condition',
    Description: 'Description',
    DescriptionPlaceholder: 'Describe your items in few sentences',
    Male: 'Men',
    Female: 'Women',
    Unisex: 'Unisex',
    chooseGender: 'Please choose gender',
    chooseCategory: 'Please choose category',
    chooseBrand: 'Please choose brand',
    chooseCondition: 'Please choose condition',
    Youritem: 'Your item',
    Status: 'Status',
    Estimatetime: 'Estimate Time',
    Notice: 'Notice',
    Account: 'Account',
    Upgrade: 'Upgrade',
    Tier: 'Tier',
    // CreditLimit: 'Credit limit',
    Wonder: 'Wonder how much your art',
    BasicInfo: 'Basic information',
    LogInfo: 'contact info',
    ShippingAddress: 'Shipping address',
    PhoneNum: 'Phone',
    Name: 'Name',
    FullName: 'Full Name',
    Address: 'Address',
    addressPlaceholder: 'Street number, building, etc.',
    PaymentMethod: 'Payment method',
    Birth: 'Date of birth',
    Nationality: 'Nationality',
    Country: 'Country',
    CountryCode: 'Country',
    FavoriteCategories: 'Favorite Categories',
    FavoriteBrands: 'Favorite Brands',
    Save: 'Save',
    Sellingitems: 'Selling items',
    Vaulteditems: 'Vaulted items',
    VaulteditemsText:"Trust us with keeping your collectibles safe and store your items for resale.",
    Purchases: 'Purchases',
    Wallet: 'Wallet',
    WalletText:"Securely store your payment details and pay on Falcons.",
    AED: 'Card Payment',
    Missionboard: 'Mission board',
    Onsale: 'On sale',
    Selected: 'Selected',
    RemoveAllSelected: 'Remove all selected',
    Lots: 'Lots',
    Frontside: 'Front View',
    Backside: 'Back View',
    BottomView: 'Bottom View',
    optional: 'Optional',
    TopView: 'Top View',
    InsideView: 'Inside',
    photoTypes: 'JPEG, PNG',
    documents: 'Documents',
    documentType: 'PDF',
    fileSizeLimit: 'File Size Limit',
    fileSizeLimitValue: '20 MB',
    keepInMindTitle: 'TO KEEP IN MIND:',
    closeUpShots: 'Close-Up Shots: ',
    closeUpShotsContent: 'Include close-up shots of brand tags, serial numbers, and any imperfections.',
    highQualityPhotos: 'High-Quality Photos: ',
    highQualityPhotosContent: 'Upload high-resolution images of the item from multiple angles (front, back, sides, and interior).',
    whiteBackground: 'White Background: ',
    whiteBackgroundContent: 'All pictures must be taken on a white background with no visible images or objects other than the item in the picture.',
    Myaddress: 'Within My Possession',
    editLocationAndMode: 'Edit Location & Mode',
    editItemDetails: 'Edit Item Details',
    editPhotos: 'Edit Photos',
    FalconsVault: 'At the Falcons vault',
    Proposedmode: 'Proposed mode',
    Proposedprice: 'Proposed price',
    Accept: 'Accept',
    Continuewithnewmode: 'Continue with new mode',
    Continuewithoriginalmode: 'Continue with original mode',
    Reject: 'Reject',
    Confirm: 'Confirm',
    Step1: 'Step 1: Print packing slip',
    Step2: 'Step 2: Upload tracking number',
    Newproposedprice: 'New proposed price',
    NewProposedPriceDesc: 'Our Falcons team has carefully verified your item and is pleased to offer you the best guaranteed price. We look forward to welcoming your item to Falcons.',
    AddtoCart: 'Add to Cart',
    AddtoCartSuccess: 'Successfully added',
    spuSizePlaceholder: 'Select Size',
    outOfStock: 'OUT OF STOCK',
    releaseDate: 'Release Date',
    AddtoCollections: 'Add to Collections',
    AllinclusiveVAT: 'All prices are VAT exclusive',
    Authenticitycertification: 'Authenticity certification',
    Deliveryworldwide: 'Delivery worldwide',
    ExteriorMaterial: 'Exterior material',
    Includes: 'Includings',
    Origin: 'Origin',
    Height: 'Height',
    Width: 'Width',
    Length: 'Length',
    Beltlength: 'Belt length',
    Casediameter: 'Case diameter',
    Yearset:"Year & Set",
    YearText:'Year',
    Features:'Featured',
    Contents:'Contents',
    SpecialFeatures:'Special Features',
    RookieCards:"Rookie Cards",
    Handledrop: 'Handle drop',
    Braceletsize: 'Bracelet size',
    Removefromcart: 'Remove from cart',
    RemovefromCollections: 'Remove from collections',
    Makeanoffer: 'Make an offer',
    Finish: 'Finish',
    Pleaseuploadatleast2photos: 'Please upload at least two photos',
    Pleaseuploadatleast5photos: 'Please upload at least five photos',
    Doyouconfirm: 'Do you confirm to submit your item?',
    Notification: 'Notification',
    MostRecent: 'Most recent',
    Earlier: 'Earlier',
    ShowMore: 'Show more',
    ViewShoppingCart: 'View shopping cart',
    UpperViewShoppingCart:"VIEW SHOPPING CART",
    Onlinetext: 'Online',
    Auctionstext:'Auctions',
    Hybridtext:'Hybrid',
    AUCTIONJOURNEY:'AUCTION JOURNEY',
    VIEWLOTS:'VIEW LOTS',
    SportsCards:'Sports Cards',
    Orchestratingarefinedsymphony:'Orchestrating a refined symphony of bids and desires',
    Experiencethepinnacle:"A seamless blend of online bidding with the thrilling ambiance of live auction events. Whether you choose to participate in-person at our opulent venues or engage remotely through our sophisticated online platform, you will have exclusive access to a meticulously curated selection of authenticated collectibles.",
    Ourliveauctionsoffer :'Our live auctions offer a unique opportunity to immerse yourself in an atmosphere of exclusivity and prestige. Surrounded by discerning collectors and enthusiasts, you will have the chance to bid on rare and exquisite pieces. Our distinguished auctioneers and expert authenticators guarantee a flawless and transparent process, ensuring each moment is unforgettable.',
    Upcomingauctions: 'Upcoming Auctions',
    Gainaccesstoacurated:"Our state-of-the-art online platform ensures a seamless and secure bidding experience, featuring meticulously authenticated items. Elevate your collection with unparalleled ease and sophistication by participating in our exclusive online auctions.",
    All: 'All',
    Livetext:'Live',
    OnlineAuctions:'Auctions',
    LivetextAuctions:"Auctions",
    HybridtextAuction:'Auctions',
    Sortby: 'Sort By',
    EndDate: 'End time: Ascending',
    Popular: 'Popular',
    Mybids: 'My bids',
    Conditionsofbusiness: 'Condition of business',
    Joinauction: 'JOIN AUCTION',
    JoinAuction: 'Join Auction',
    BrowseBreaks: 'BROWSE THE BREAKS',
    JoinAuctionITip: 'As per conversion rates, delivery fees and VAT payments, the final price of your order will be slightly altered.',
    Notifyme: 'SAVE & NOTIFY ME',
    LengthRule: 'Can only select up to 3 items',
    EnterRule: 'Please enter',
    PhoneRule: 'Please enter a valid phone number.',
    Suggestedauctions: 'Suggested auctions',
    Currentprice: 'Current price',
    Estimate: 'Estimate',
    Placeabid: 'PLACE A HIGHER BID',
    State: 'Area',
    StateName: 'Area',
    City: 'City',
    CityName: 'City',
    PostCode: 'Postal code',
    VIEWAUCTION: 'VIEW AUCTION',
    Yourbid: 'Your bid',
    Setmaxbid: 'Place Maximum Bid',
    BidPriceChange: 'Current price has changed. Please select another price.',
    Bidplacedsuccessfully: 'Bid placed successfully',
    Gobacktolot: 'Go back to lot',
    ConfirmAndPay: 'Confirm and pay',
    GoBack: 'Go back',
    SelectDeliveryAddress: 'Select delivery address',
    KeepInVault: 'Keep in vault',
    AddressAndContact: 'Address and contact',
    OrderSummary: 'Order summary',
    FirstName: 'First name',
    LastName: 'Last name',
    Estimatedprice: 'Estimated price',
    Terminformation: 'Terms & Conditions',
    ShowAll: 'Show all',
    ShowLess: 'Show less',
    MakeDefaultAddress: 'Set as default address',
    Vat: 'VAT',
    VerifyAccount: 'Verify your account',
    VerifyAccountContent1: `We require your account to be verified in order to participate in live auctions.
`,
    VerifyAccountContent2: 'Otherwise, your access will be limited to Buy marketplace and online auctions.',
    WhyNeed: 'Why is it needed?',
    WhyNeed1: 'To participate in the live auctions and unlock more precious collections.',
    WhyNeed2: 'To complete our KYC process and help us build a clean and safe shopping environment.',
    WhyNeed3: 'To offer our exclusive customers premium service.',
    SelectOnComputer: 'Select on your computer',
    uploadFile: 'Upload file',
    DoLater: "I WILL DO IT LATER",
    // UploadAtLeastTwo: 'Please upload your ID',
    UserIdentification: 'ID (driver license, passport etc)',
    newUserIdentification:'ID (driver license, passport)',
    newWhyNeed:'Why do we Verify?',
    PaymentHistory: 'Payment history',
    AuctionHistory: 'Auction purchase history',
    FinancialStatement: 'Financial statement',
    CreditStatement: 'Credit card limit statement',
    CryptocurrencyAccount: 'Cryptocurrency account',
    PropertyStatement: 'Statement of property value',
    UploadLeastTwo: 'Upload your ID',
    UploadedDocuments: 'Uploaded documents',
    AddAnotherDocument: 'Add another document',
    BackToShopping: 'BACK TO SHOPPING',
    DocumentReview: 'Document review',
    newDocumentReview:'Under Review',
    VerificationStatus: 'Verification status',
    Saved: 'Saved',
    PendingReview: 'Pending review',
    Approved: 'Approved',
    Rejected: 'Rejected',
    verifyUploadTip: 'Please provide clear photos of your documents. The name on the document must match the registered name.',
    Today: 'Today',
    Yesterday: 'Yesterday',
    MarkAll: 'Mark all as read',
    MakeanofferDec01: 'Price too high?',
    MakeanofferDec02:'Propose an offer with your desired price to the seller.',
    proposeprice: 'Propose a different price',
    Cancel: 'CANCEL',
    Pleaseentertheprice: 'Please enter the price',
    Thepricecannotbelowerthantheinitialprice: 'The price cannot be lower than the minimum price',
    newoffer: "You've made a new offer of",
    Buyat: 'Buy at',
    Offeredprice: 'Buyer offers',
    Action: 'Action',
    Price: 'Price',
    Yourofferof: 'Your offer of',
    hasbeenaccepted: 'has been accepted',
    UploadIdMust: 'Please upload ID first',
    Hours: 'hours',
    EstimatedTime: 'Estimated time',
    verContactUs:'CONTACT US',
    newEstTime:'EST.',
    Code: 'Code',
    Hotdeals: 'Concessions',
    Newarrivals: 'New Arrivals',
    Onlineauctions: 'Online Auctions',
    Liveauctions: 'Live Auctions',
    Auctionhistory: 'Auction Archives',
    Editorspicks: 'Editor’s picks',
    Apply: 'Apply',
    Resetfilters: 'RESET FILTERS',
    Searchresults: 'SEARCH RESULTS',
    Objects: 'Objects',
    Type: 'Type',
    Historical: 'Historical',
    Myauctions: 'My auctions',
    Mysaved: 'My saved',
    Discovermore: 'Discover more',
    Additionalinfo: 'Additional info',
    Productcondition: 'PRODUCT CONDITIONS',
    ProductCondition1Desc: 'Items in pristine condition are brand new, unused, and free from any defects. They exhibit flawless quality and are in perfect, mint condition.',
    ProductCondition2Desc: ' Items in excellent condition are gently used or may have minor signs of wear. However, they still maintain a high level of quality and appearance, with minimal to no visible flaws.',
    ProductCondition3Desc: 'Items in good condition have been moderately used and may show signs of wear such as light scratches or scuffs. Despite these minor imperfections, they remain in overall good condition and retain their functionality and appeal.',
    ProductCondition4Desc: ' Items in fair condition have been noticeably used and may exhibit visible signs of wear, such as scratches, stains, or minor damage. While they may still be functional, they may require some restoration or refurbishment to improve their appearance and usability.',
    Productdetails: 'PRODUCT DETAILS',
    CommingUpNext: 'REMOVE COMPLETLY',
    Exteriormaterial: 'Exterior material',
    Interiormaterial: 'Interior material',
    Hardware: 'Hardware',
    Size: 'Size (L*W*H)',
    Movementtype: 'Movement type',
    CountryofOrigin: 'Country of origin',
    Yearofproduction: 'Year of production',
    Includings: 'Includings',
    waterResistance: 'Water resistance',
    caseMaterial: 'Case material',
    braceletMaterial: 'Bracelet material',
    MODELYear:'MODEL YEAR',
    makeofferdesc: 'The price is too high? You may make an offer to the seller and tell the seller your desired price.',
    Okay: 'OKAY',
    SellBannerWords: 'At Falcons, timeless treasures transcend time. ',
    Howitworks: 'How It Works',
    Step: 'Step',
    Preliminaryquotation: 'Preliminary Quotation',
    font01: `Submit your item online and our team will give you a preliminary quotation.`,
    Secondaryquotation: 'Arrange Collection',
    finalQuotation:"Final Quotation",
    font02: `If you're satisfied, you may arrange a free collection for authentication and final quotation.`,
    Listing: 'List & Earn',
    font03: `After approval, we'll refine the piece, capture high-quality photos, and list it. Once the piece sells, the owed amount will be credited to your Falcons Wallet.`,
    quotationRejectedTitle: 'QUOTATION REJECTED',
    quotationRejectedContent1: 'Thank you for your response.',
    quotationRejectedContent2: 'A member of our customer service team will be in touch shortly.',
    Waystosell: 'Ways to sell',
    WaystosellAuction: 'Auction Your Product',
    WaystosellSell: 'Sell Your Product',
    auctionTip1: 'The sold price will be determined through bidding among participants. The bidder offering the highest price within the effective timeframe will secure the purchase of the item. ',
    auctionNow: 'Auction Now',
    sellTip1: 'Items are displayed at fixed prices on our marketplace for immediate purchase. Once authenticated and approved, your item will join our listings. ',
    sell: 'Sell',
    recentlyAuctioned: 'Recently Auctioned',
    Recentbids: 'Recently Sold',
    BuyNow: 'Buy Now',
    VIPselling: 'VIP selling',
    VIPSellingInfo: 'Looking to sell your luxury pieces? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items.',
    Toapply: 'APPLY HERE',
    FAQ: 'FAQ',
    Blog: 'Blog',
    missionAndVision: ' Mission & Vision',
    pressAndNews: 'Press & News',
    Readytosell: 'Ready to sell?',
    vipWords: ' Want to sell some precious items with high value? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items. Then you could sit back and relax. We will do everything else for you. Looking to sell your luxury pieces? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items. Then you could sit back and relax. Let us handle the rest.',
    Mobile: 'Mobile',
    Pleaseenterthemobilephone: 'Please enter the mobile phone',
    Default: 'Default Address',
    DeliveryAddress: 'Delivery address',
    ConfirmAddress: 'Confirm address',
    SendToVault: 'Send to Falcons vault',
    SendToVaultContent: 'If you have not decided where to store the item or would like to have the chance to resell the item you can trust us to keep your collectibles safe. ',
    WalletContent: 'Pay with the balance in your Falcons Wallet.',
    PayPal: 'PayPal',
    PaypalTitle: 'PayPal Payment (USD)',
    PayPalContent: 'Pay with PayPal e-wallet if you have a PayPal account.',
    Pay: 'Pay Now',
    KeepLeastOne: 'Please keep at least one address saved.',
    ChooseFirst: 'Please choose the available shipping method first',
    FillFormFirst: 'Please fill out the form first',
    Locale: 'en_US',
    onProgress: 'In progress',
    OrderConfirmed: 'Your order is being carefully processed.',
    OrderConfirmedContent: 'Thank you for shopping with us.',
    Order: 'Order',
    PaymentSuccessful: 'Payment successful',
    PaymentUnsuccessful: 'Payment unsuccessful',
    ContinueShopping: 'Continue shopping',
    ContactUs: 'Contact us',
    pickupAt: 'Please pick up at ',
    PaymentUnsuccessfulContent: 'Please update the payment details. Otherwise the order will be canceled after ',
    Sellyouritem: 'Sell your item',
    Itemdetails: 'Item Details',
    Uploadphotos: 'Upload Photos',
    sellFont01: 'i. You may provide your item info online and our staff will give you an estimated quotation range.',
    sellFont02: 'ii. If you are satisfied with the quotation, and you may send your item to Falcons, and we will update the quotation based on the real item.',
    sellFont03: 'iii. If you are still satisfied with the updated quotation, then we will polish the item, take HD pictures, and list the item. If you would still like to proceed, we will get your item ready for sale and list them online.',
    Itemlocation: 'Item Location',
    localtionFont01: `If an item is with you, we will be in touch with you for delivery to the Falcons warehouse.`,
    localtionFont02: 'If you have purchased from Falcons and stored the item in Falcons\' vault, you may select from your vaulted collections and list them.',
    sellmodeFont01: `Sold price will be decided by the bidding of participants. The bidder who offers higher price within the effective time period will purchase the item. `,
    sellmodeFont02: `Items are listed at fixed-price in the marketplace. Any user can purchase the item and place an order immediately.`,
    Selectyouritem: 'Select your item',
    Pleasechooseyouritem: 'Please choose your item',
    uploadText: 'To upload an image please click here or use the drag and drop',
    uploadTitleText: 'You can also use the Falcons mobile app to take & upload the item images. Please scan the QR code & follow the instrucitons.',
    Confirmandsubmit: 'Confirm and submit',
    // Whatnext: "What's Next?",
    // nextText: 'Falcons\' professional staff will review your submission, verify and grade your item based on the information you provided, and then give you a estimated quotation range. Should you have any questions, or have not received the quotation after <span style="font-family: \'Avenir Heavy\'">48 hours</span>, please contact our customer service for more help.',
    submitSuccessMsg: 'Our team will review your submission, verify and grade your item based on the information provided, and then deliver an estimated quotation.',
    Addanotheritem: 'Add another item',
    sellanotheritem: 'ADD ANOTHER ITEM',
    Backtohomepage: 'BACK TO HOME PAGE',
    sellSubmitSuccessContent1: 'Our team will review, authenticate, and grade your submission based on the provided information to provide an accurate quotation. Falcons retains the exclusive right to determine the price of your piece based on the detailed information.',
    // sellSubmitSuccessContent2: 'Falcons retains the exclusive privilege to set the price of your item, based on the detailed information.',
    font10: 'Should you have any questions, or have not received the quotation after 48 hours, please contact our customer service for more help.',
    Itemreview: 'Item review',
    itemApproved: 'CONGRATULATIONS! YOUR ITEM IS APPROVED',
    Shippingdetails: 'Shipping Details',
    Acceptmode: 'Accept mode',
    Keeporiginalmode: 'Keep original mode',
    Rejectreturn: 'Reject & return',
    Shippingwithdelivery: 'Seller-handled shipping',
    shippingFont01: 'You may choose your own delivery service and send your item to us.',
    shippingFont02: 'If you live in Dubai, you may drop off your item at our warehouse.',
    FanclonsAddress: 'Unit C20, C21 Feeder Commercial Centre, Plot No. 1479, Me\'Aisem First, Dubai Production City, UAE',
    DropoffatFalcons: 'Drop off at Falcons’ warehouse.',
    shippingStep01Num: 'Step 1',
    shippingStep01Title: 'Print packing slip',
    shippingStep01Text: 'Please print the packing slip and attach it to the package.',
    shippingStep02Num: 'Step 2',
    shippingStep02Title: 'Send your item',
    shippingStep02Text: 'Enclose the printed packing slip and send it with the item to the address:',
    shippingStep03Num: 'Step 3',
    shippingStep03Title: 'Upload tracking number',
    shippingStep03Text: 'After the item is shipped, please attach the tracking number so that we and you can track the delivery process.',
    Download: 'DOWNLOAD',
    Selectyourcourier: 'Select your courier',
    Trackingnumber: 'Tracking number',
    pickupByFalcons: 'Or pickup by Falcons',
    Acceptprice: 'Accept price',
    BacktoSellingItems: 'Back to Selling Items',
    Profilesettings: 'Profile settings',
    UnPaid: 'Unpaid',
    Paid: 'Awaiting for processing',
    PaidContent: 'Our team have received your order and are preparing your item.',
    ShippingInProgress: 'Shipping in progress',
    ShippingInProgressContent: 'Your item has been packed and delivered by our cooperated logistics company. It is on the way to your place.',
    Completed: 'Completed',
    completeTitle: 'Your order has been completed.',
    CompletedContent: 'You have received your item. The order is completed. Should you have any questions, please feel free to contact our customer support team.',
    Canceled: 'Canceled',
    Date: 'Date',
    ShippingAmount: 'Shipping',
    ShippingFee: 'Shipping fee',
    ShippingDays: 'Shipping days',
    promptText:'VAT Inclusive, Shipping & Insurance Included',
    Mode: 'Mode',
    Items: 'Items',
    PhonePrefix: 'Phone code',
    ShippingDetail: 'Shipping detail',
    PaymentSuccessfulContent: 'Thank you for shopping with us.',
    Shipping: 'Shipping',
    AddAddress: 'Add New Address',
    DropOffAtFW: 'Drop off at Falcons’ warehouse',
    IfyouDrop: 'If you live in Dubai, you may drop off your item at our warehouse',
    FWAddress: 'Address: Unit C20, C21 Feeder Commercial Centre, Plot No. 1479, Me\'Aisem First, Dubai Production City, UAE',
    Withdraw: 'Withdraw',
    Deliverypaymentmethod: 'Delivery & payment method',
    Confirmationpayment: 'Confirmation & payment',
    Withdrawitems: 'Withdraw items',
    DropOff: 'Drop off',
    Purchase: 'Purchase',
    CurrentInCart: 'Currently in cart',
    NotificationCenter: 'Notification centre',
    unread: 'Unread',
    read: 'Read',
    yourBag: 'YOUR BAG',
    goToBag: 'GO TO BAG',
    includingVat: 'Including VAT',
    more: 'MORE',
    CartSize:'Size',
    Quantity: 'Quantity',
    Past: 'Past',
    TrackingNumber: 'Tracking number',
    CanceledContent: 'Your order is canceled',
    contactTitle01: 'Get in touch directly',
    contactText01: 'Email: customersupport{\'@\'}falcons.com',
    // contactText02: 'Phone: 800 800 8888 (Mon to Fri 10 am to 4 pm GST)',
    contactTitle02: 'Visit us in person',
    contactText03: 'Our office: Room1001, ABC Tower, DEF Street, Dubai, 12345',
    contactText04: 'Our warehouse: No. 145, XYZ Street, Dubai, 13456',
    contactText05: 'Our auction room: Room 108-110, XXX Plaza, Dubai, 13579',
    Inquiryform: 'Inquiry form',
    Topic: 'Topic',
    DeliveryInformation: 'Delivery Information',
    ReturnsAndRefunds: 'Returns and Refunds',
    MyOrder: 'My order',
    InternationalOrder: 'International order',
    ProductInformation: 'Product Information',
    PromotionsMakeOffer: 'Make an Offer',
    Complaints: 'Complaints',
    TechnicalIssues: 'Technical Issues',
    Message: 'Message',
    Submityourmessage: 'Submit your message',
    Ascending: 'Ascending',
    Descending: 'Descending',
    mostpopular: 'Most popular',
    Recentlyadded: 'Recently added',
    NewestFirst: 'NEWEST FIRST',
    OldestFirst: 'OLDEST FIRST',
    Bestcondition: 'Best condition',
    Relevance: 'Relevance',
    pricelowtohigh: 'Lowest price',
    pricehightolow: 'Highest price',
    Sellingtype: 'Selling type',
    lotnumberascending: 'Lot number: Ascending',
    lotnumberdescending: 'Lot number: Descending',
    popularity: 'Popularity',
    reservericeascending: 'Reserve price: Ascending',
    reservericedescending: 'Reserve price: Descending',
    depositFont01: 'Please authorize your credit card for the deposit of',
    depositFont02: 'Why authorize your credit card?',
    depositFont03: 'We are trying to build a clean environment of auctions. We ask every customer to authorize credit card for a deposit to make sure everyone pays for the item they bid for. If you did not bid for anything, or have paid for all your invoices, the authorization will be canceled. But if you fail to pay within time limit, the deposit will be charged.',
    CreditCard: 'Credit card',
    WithdrawAmount: 'Withdraw amount',
    PayoutMethod: 'Payout method',
    WithdrawContent: 'Select a withdrawal method below. Approximate withdrawal time is 48 hours.',
    BankTransfer: 'Bank transfer',
    channelTypePlaceholder: 'Select you preferred payout method',
    YourBalance: 'Your balance',
    Amount: 'Amount',
    OrderID: 'Order Number (if applicable)',
    AccountholderName: 'Account holder name',
    AccountNumber: 'Account number',
    IbanNumber: 'IBAN number',
    SwiftCode: 'Swift code',
    BankName: 'Bank name',
    BranchName: 'Branch name',
    inputUserName: 'Please input your full name',
    inputEmail: 'Please input your email',
    inputMessage: 'Please input your message',
    PaypalAccount: 'Paypal account',
    LimitValidAmount: 'Please input valid amount',
    LimitTwoDecimal: 'Please input two decimal places only',
    ExceedWithdraw: 'This exceeds the amount you can withdraw',
    AskToReturn: 'Ask to return',
    VerifyYourAccount: 'Verify your account to participate.',
    VerifyYourAccountContent: 'Your access will be limited to Buy Now marketplace and online auctions.',
    Trackyourparcel: 'Track your parcel',
    Carrier: 'Carrier',
    Shippingupdates: 'Shipping updates',
    Aramex: 'Aramex',
    OrderNumber: 'Order number',
    Photo: 'Photo',
    Verify: 'Verify',
    Popculturecollectibles: 'Pop Culture Collectibles',
    Sportmemorabilia: 'Sport Memorabilia',
    Sporttradingcardcollectibles: 'Sport Trading Card Collectibles',
    Heritagebrands: 'Heritage Brands',
    Carscollectibles: 'Cars & Collectibles',
    Toyscomics: 'Toys & Comics',
    LouisVuitton: 'Louis Vuitton',
    Hermes: 'Hermes',
    Chanel: 'Chanel',
    Dior: 'Dior',
    Gucci: 'Gucci',
    Rolex: 'Rolex',
    Omega: 'Omega',
    TagHeuer: 'Tag Heuer',
    Cartier: 'Cartier',
    Breitling: 'Breitling',
    banedTxt: 'Unfortunately, your account is suspended because it violates our user policy. Please contact us for more information.',
    Refund: 'Refund',
    RefundContent: 'Your order had been successfully refunded.',
    WelcomeTo: 'Welcome to',
    ContinueWithGoogle: 'Continue with Google',
    ContinueWithFacebook: 'Continue with Facebook',
    Or: 'or',
    DownloadInvoice: 'Download invoice',
    Falcons: 'Falcons',
    VipTier: 'VIP Tier',
    Verified: 'Verified',
    Added: 'Added',
    Auction: 'Auction',
    sportsCards: 'Sports Collectibles',
    liveMultiSports: 'Live Sports Card Breaks',
    onlineLiveShopping: 'Online Boutique',
    collectorHistory: 'Collector Archives',
    Handbags: 'Handbags',
    Watches: 'Timepieces',
    From: 'From',
    Add: 'Add',
    Brands: 'Brands',
    Live: 'Live',
    Upcoming: 'Upcoming',
    Ongoing: 'Ongoing',
    ResetYourPassword: 'Reset your password',
    ResetYourPasswordContent: "Please enter an email that you used to register your account with & we'll be sending you a link for reset.",
    CheckYourInbox: 'Check your inbox',
    CheckYourInboxContentFirst: 'A reset link was successfully sent to ',
    CheckYourInboxContentSecond: 'Please check your inbox and follow the instructions given in the email.',
    ResendLink: 'Resend link',
    SetYourNewPassword: 'Set your new password',
    ConfirmNewPasswordContent: 'Your new password has been successfully updated.',
    ReceiveFalconsEmail: "Receive Falcons' Emails",
    EmailPreference: 'Email Preference',
    CountryOfResidence: 'Country of Residence',
    ShowDetails: 'Show details',
    SeeAllNotifications: 'See all notifications',
    Thepricecannotbehigherthantheinitialprice: 'The price cannot be higher than the current price',
    CodeRule: 'Please enter a four digit verification code.',
    VerificationEmail: 'Verify your email',
    AccountCreatedContent: 'To use the full features of the auction you need to verify identity.',
    AccountHasbeenCreated: 'Account has been created',
    WeHaveSentCode: "We've sent you a code to ",
    CheckInboxCode: ' to confirm  your email address. Please check your inbox and input it here.',
    ComingSoon: 'Coming soon!',
    Estimateprice: 'Estimated price',
    PleaseuploadaFrontandBackPhoto: 'Please upload all required photos',
    Yes: 'Yes',
    No: 'No',
    Delivery: 'Delivery',
    Payment: 'Confirmation & payment',
    DeliveryMethod: 'Delivery Address',
    Gotopurchase: 'Go to purchase',
    Backtolots: 'Back to lots',
    AddYourPhoneNumber: 'Add your phone number',
    AddYourPhoneNumberContent: 'Please verify your phone number to complete checkout and join our auctions.',
    VerifyYourPhoneNumber: 'Verify your phone number',
    VerifyYourPhoneNumberContent: ' to verify your phone number. Please check your message and input it here',
    UploadImgMessage: 'Please upload an image format file',
    UploadPdfMessage: 'Please upload an image or PDF format file',
    EmailSentCode: "We've sent you a code to your email, please check your inbox and input it here",
    Resend: 'Resend',
    SendtoFalconsvault: 'Send to Falcons vault',
    vaultWords: 'Falcons owns a professional vault with world class security and storage technology to keep our items. lf you have not decided where to receive the item, or would like to keep the chance to resell the item, trust us with keeping your purchased items.',
    UploadSizeMessage: 'The file size should be less than 10MB',
    NewPasswordRule: 'Please enter a new password',
    AddressRule: 'Please enter a valid English address',
    NewEmailRule: 'Please enter a new email',
    SubmitOrder: 'Submit order',
    ReservePrice: 'Reserve price',
    YourBid: 'Your Bid',
    YourBidWinsPrefix: 'YOUR BID',
    YourBidWinsPostfix: 'WINS',
    YourBidLose: 'SOMEONE OUTBID YOU',
    StartTimeAscending: 'Start time: Ascending',
    EndTimeAscending: 'End time: Ascending',
    Lotclosesat: 'Lot closes at: ',
    Withdrawfee: 'Withdraw fee',
    PickUpAtFW: 'Pick up at Falcons’ warehouse',
    IfyouDropPickUp: 'You may pick up your item at our warehouse. ',
    SelectProductsFirst: 'Please select the product first',
    300001: 'Invalid email or password. Please try again.',
    310002: 'This email has been registered.',
    310004: 'This email has been registered using Google, Facebook or Apple.',
    310101: 'You cannot change email when you log in with an external account',
    330001: 'Sorry, you cannot login with this account.',
    330002: 'Sorry, you have not verified your identity.',
    330003: 'Sorry, your VIP tier does not meet the criteria',
    330005: 'Wrong verification code.',
    330006: 'Sorry, you cannot participate in any auction with this account.',
    540306: 'You can not make an offer to your own product.',
    402006: 'You cannot bid your own lots.',
    410010: 'You need to join the auction first.',
    420001: 'Sorry, you cannot participate in any auction with this account.',
    550005: 'Amount exceeds current stock level',
    MaxTenAddress: 'You can only save up to 10 addresses',
    AdditionalinfoText: 'Please check our',
    and: 'and',
    TermsConditions: 'Terms & Conditions',
    FAQs: 'FAQs',
    auctionPN:'Phone Number',
    PhoneRepeat: 'Please enter a new phone number',
    items: 'items',
    FilterBy: 'FILTER BY',
    reviewText: 'OVERVIEW OF SELLING ITEM',
    submittingText: 'Thank you for submitting your piece',
    maxBidTxt: 'If a maximum bid is placed, the platform will then place incremental bids on your behalf, up to a specified maximum amount.',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    saleUpTo:'UP TO 70% OFF',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    PM: 'PM',
    AM: 'AM',
    Sold: 'Sold',
    BirthDOB: 'Date of Birth',
    Search: 'Search',
    About: 'About',
    Authenticity: 'Authenticity',
    Aboutus: 'About us',
    // Contacts: 'Contacts',
    DeliveryPayment: 'Delivery & Payment',
    Other: 'Other',
    PrivacyPolicy: 'Privacy Policy',
    TermsofService: 'Terms & Conditions',
    Customers: 'Customers',
    Privacy: 'Privacy',
    Categories: 'Categories',
    Mobileapp: 'Mobile App',
    mobileApp:'Mobile app',
    falconsReserved: 'Falcons © 2024. All rights reserved.',
    Pleaseenterthecorrectprice: 'Please enter the correct price',
    mobileTxt: 'To make your mobile shopping experience better download our app',
    Continuewithbrowser: 'Continue with browser',
    ResendCode: 'Resend code',
    ConfirmDeleteItem: 'Confirm to delete this item from shopping cart?',
    ByPlacingOrder: 'By placing an order, you agree to Falcons ',
    TermsOfFalcons: ' of Falcons',
    SubmitOrderCondition: 'By placing an order, you should agree to the Terms & Conditions of Falcons',
    OurOffice: 'Our office',
    Filter: 'Filter',
    Concessions: 'Concessions',
    SConcept: 'The S Concept',
    discoverSConcept: 'Discover The S Concept',
    aboutFalcons: 'About Falcons',
    aboutFalconsContent: 'Falcons is a premier destination for luxury. We offer online and live auctions as well as private sales of rare collectibles and ultra-luxury pieces. We believe that the true beauty of iconic pieces lies in their ability to captivate, inspire, and tell extraordinary stories. Our auctions and private sales are meticulously curated to offer our esteemed clientele a remarkable collection of luxury pieces. ',
    WhyFalcons: 'Why sell on Falcons?',
    WhyFalconsContent: `Falcons is a trusted and dynamic marketplace for luxury goods. With our team of specialists we can help you get the most of your pieces by reaching our community of buyers. Our premium "White Glove Service" ensures that your experience on our platform is not only efficient but also highly individualized, meeting your specific needs and expectations.`,
    HowSellWithFalcons: 'How to sell on Falcons?',
    HowSellWithFalconsContent: 'Start by creating an account online and submitting your items. Our specialist team will offer a quotation range depending on how they see your item selling. If you are satisfied with the quotation and we re-asses in person, we will get your item ready for sale and list them online.',
    WhatIsFalcons: 'What is Falcons?',
    WhatIsFalconsContent: `Falcons is a premier destination for luxury. We offer online and live auctions as well as private sales of rare collectibles and ultra-luxury pieces.We believe that the true beauty of iconic pieces lies in their ability to captivate, inspire, and tell extraordinary stories. Our auctions and private sales are meticulously curated to offer our esteemed clientele a remarkable collection of luxury pieces. With a passion for the arts and a deep appreciation for craftsmanship, we strive to create an unparalleled experience for collectors and art enthusiasts alike. Our team of experts tirelessly scour the globe to source rare and remarkable treasures, ensuring that our offerings showcase the pinnacle of creativity, authenticity, and refinement. <br/> What sets Falcons apart is our unwavering commitment to the utmost privacy and exclusivity for our esteemed clientele. We understand that acquiring extraordinary pieces is an inherently personal experience, and we tailor our services to ensure your journey with us is a truly exceptional one. Whether you are looking to add to your existing collection, seeking an elusive masterpiece, or simply exploring the world of luxury, our discreet and knowledgeable team is here to guide you every step of the way. We also offer a platform for sellers to sell their pre-loved luxury pieces and find them new homes.Our vision is to foster a community that celebrates the passion for rare collectibles. Through our private sales and auctions, we unite collectors, connoisseurs, and industry professionals, creating a platform that nurtures the appreciation of luxury across the globe.Join us on this exhilarating journey as we unlock the doors to a universe filled with exceptional beauty, timeless elegance, and the thrill of uncovering extraordinary masterpieces. Welcome to Falcons, where the pursuit of luxury knows no bounds.`,
    Others: 'Others',
    PreviousSearches: 'Previous searches',
    AllResults: 'All results',
    AllHandbags:'All handbags',
    AllWatches:'All timepieces',
    Minimum:'Minimum',
    errorText:'Something goes wrong. Please try again.',
    310013:'Invalid code',
    suggest01:'We think your item is more suitable for ',
    suggest02:' mode. Would you like to change your selling mode?',
    endTimeAscending:'End time: Ascending',
    startTimeAscending:'Start time: Ascending',
    mostPopular:'Most popular',
    currentPriceAscending:'Current price:Ascending',
    currentPriceDescending:'Current price:Descending',
    AboutToOpen:'About to open',
    Open:'Open',
    Deatilshere:'DETAILS HERE',
    ShowCatelog: 'Show Catelog',
    verifiedText:'You have not verified your identification.',
    Verifynow:'Verify now',
    Doitlater:'Complete Later',
    registeredText:'You have not registered your phone number.',
    Doitnow:'Do it now',
    testFont:'Oyster Cosmograph',
    Pristine:'pristine',
    Excellent:'excellent',
    Good:'good',
    Fair:'fair',
    ad_font01:'Sell with Falcons',
    ad_font02:'Wonder how much your collections might be worth? Having too many unwanted designer handbags filing your closet? Or looking to refresh your watch collection? Sell with Falcons and our professional team will help you get the best value of your items.',
    ad_button:'Sell items',
    WelcomeToFalconsHome:'Welcome to The S Concept',
    SignupHome:'SIGN UP',
    SetYourPassword:'SET YOUR NEW PASSWORD',
    ResetYourPasswordHome:'RESET YOUR PASSWORD',
    CheckYourInboxHome:'CHECK YOUR INBOX',
    VerificationEmailHome: 'VERIFY YOUR EMAIL',
    AddYourPhoneNumberHome: 'ADD YOUR PHONE NUMBER',
    VerifyYourPhoneNumberHome: 'VERIFY YOUR PHONE NUMBER',
    testFont01:'Mini Kelly',
    testFont02:'Kelly 25',
    testFont03:'Seamaster Aqua Terra',
    testFont08: 'Himalaya Birkin 25',
    testFont04:'Birkin 30',
    testFont05:'Speedmaster Professional',
    testFont06:'Kelly Lakis 35',
    testFont07:'Submariner',
    testFont09:'Bird on a rock',
    ConfirmPasswordHome:'Confirm password',
    SignUpHome:'SIGN UP',
    Timepieces:'Timepieces',
    ViewMore:'View more',
    DiscoverMore:'Discover more',
    browseAuctions: 'BROWSE AUCTION',
    viewDetails: 'Explore Collection',
    sneakerBannerTitle: 'Jordans 04',
    sneakerBannerContent: 'Elevating sneakers to coveted collectibles in the world of urban fashion.',
    sneakerBannerShop: 'SHOP THE SNEAKER',
    exploreSneakers: 'Explore all sneakers',
    clothingBannerTitle: 'Supreme',
    clothingBannerContent: 'Quintessential pieces designed to elevate everyday streetwear.',
    clothingBannerShop: 'SHOP THE COLLECTION',
    exploreClothing: 'Explore all apparel',
    EXCLUSIVEAUCTION:'EXCLUSIVE AUCTION',
    HeritageUnveiled:'Heritage Unveiled',
    Whereprestige:'Bid with distinction on the essence of luxury. ',
    Verifyyouraccount:'Verify your account to participate',
    DateText:'Date:',
    SeptemberText:'28 September 2024',
    LocationText:'Location:',
    BurjAlArab:'Burj Al Arab',
    // Doyouconfirm:'Do you confirm to submit your item?',
    // Notification:'Notification',
    // MostRecent:'Most Recent',
    // Earlier:'Earlier',
    // ShowMore:'Show More',
    // ViewShoppingCart:'View Shopping Cart',
    // Online:'Online',
    // Upcomingauctions:'Upcoming auctions',
    // All:'All',
    // Sortby:'Sort by',
    // EndDate:'End Date-Ascending',
    // Popular:'Popular',
    // Mybids:'My bids',
    // Conditionsofbusiness:'Conditions of business',
    // Joinauction:'Join auction',
    // Notifyme:'Save & notify me',
    // LengthRule:'can only be selected up to 3 items.',
    // EnterRule:'Please enter',
    // PhoneRule:'Please enter a valid phone number.',
    // Suggestedauctions:'Suggested auctions',
    // Currentprice:'Current price',
    // Estimate:'Estimate',
    // Placeabid:'Place a bid',
    // State:'State',
    // StateName:'State',
    // City:'City',
    // CityName:'City',
    // PostCode:'Post code',
    // VIEWAUCTION:'VIEW AUCTION',
    // Yourbid:'Your bid',
    // Setmaxbid:'Place maximum bid',
    // Bidplacedsuccessfully:'Bid placed successfully',
    // Gobacktolot:'Go back to lot',
    // ConfirmAndPay:'Confirm and pay',
    // GoBack:'Go back',
    // SelectDeliveryAddress:'Select delivery address',
    // KeepInVault:'Keep in vault',
    // AddressAndContact:'Address and contact',
    // OrderSummary:'Order summary',
    // FirstName:'First name',
    // LastName:'Last name',
    // Estimatedprice:'Estimated price',
    // Terminformation:'Term & information',
    // ShowAll:'Show all',
    // ShowLess:'Show less',
    // MakeDefaultAddress:'Set as default address',
    // Vat:'VAT',
    // VerifyAccount:'Verify your account',
    // VerifyAccountContent:'We require your account to be verified in order to participate in live auctions. Otherwise, your access will be limited to Buy Now marketplace and online auctions.',
    // WhyNeed:'Why is it needed?',
    // WhyNeed1:'To participate in the live auctions and unlock more precious collections.',
    // WhyNeed2:'To complete our KYC process and help us with building a clean and safe shopping environment.',
    // WhyNeed3:'Enjoy our premium service for our high-net-worth customers.',
    // SelectOnComputer:'Select on your computer',
    // DoLater:"I'll do it later",
    // UploadAtLeastTwo:'Please upload at least two files',
    // UserIdentification:'ID (driver license, passport etc',
    // PaymentHistory:'Payment history',
    // AuctionHistory:'Auction purchase history',
    // FinancialStatement:'Financial statement',
    // CreditStatement:'Credit card limit statement',
    // CryptocurrencyAccount:'Cryptocurrency account',
    // PropertyStatement:'Statement of property value',
    // UploadLeastTwo:'Upload at least 2 documents',
    // UploadedDocuments:'Uploaded documents',
    // AddAnotherDocument:'Add another document',
    // BackToShopping:'Back to shopping',
    // VerificationStatus:'Verification status',
    // Saved:'Saved',
    // PendingReview:'Pending review',
    // Approved:'Approved',
    // Rejected:'Rejected',
    // verifyUploadTip:'Please provide clear, unobstructed photos of the document. The name on the document must match the registered name.',
    // Today:'Today',
    // Yesterday:'Yesterday',
    // MarkAll:'Mark all as read',
    // MakeanofferDec:'The price is too high? You may make an offer to the seller and tell the seller your desired price.',
    // proposeprice:'Propose a different price',
    // Cancel:'Cancel',
    // Pleaseentertheprice:'Please enter the price',
    // Thepricecannotbelowerthantheinitialprice:'The price cannot be lower than the initial price',
    // newoffer:"You've made a new offer of",
    // Buyat:'Buy at',
    // Offeredprice:'Buyer offers',
    // Action:'Action',
    // Price:'Price',
    // Yourofferof:'Your offer of',
    // hasbeenaccepted:'has been accepted',
    // UploadIdMust:'Please upload ID first',
    // Hours:'hours',
    // EstimatedTime:'Estimated time',
    // Code:'Code',
    // Hotdeals:'Hot deals',
    // Newarrivals:'New arrivals',
    // Onlineauctions:'Online auctions',
    // Liveauctions:'Live auctions',
    // Auctionhistory:'Auction history',
    // Editorspicks:'Editor’s picks',
    // Apply:'Apply',
    // Resetfilters:'Reset filters',
    // Searchresults:'Search results',
    // Objects:'Objects',
    // Type:'Type',
    // Historical:'Historical',
    // Myauctions:'My auctions',
    // Mysaved:'My saved',
    // Youmayalsolike:'You may also like',
    // Additionalinfo:'Additional info',
    // Productcondition:'Product condition',
    // Productdetails:'Product details',
    // Exteriormaterial:'Exterior material',
    // Interiormaterial:'Interior material',
    // Hardware:'Hardware',
    // Movementtype:'Movement type',
    // CountryofOrigin:'Country of Origin',
    // Yearofproduction:'Year of production',
    // Includings:'Includings',
    // waterResistance:'Water Resistance',
    // caseMaterial:'Case Material',
    // braceletMaterial:'bracelet Material',
    // makeofferdesc:'The price is too high? You may make an offer to the seller and tell the seller your desired price.',
    // Okay:'Okay',
    // SellBannerWords:'Falcons will have professional staff verifying and grading your submitted items, and we will give quotations. We provide 2 way of selling: Buy Now marketplace and Auction. After receiving the items, we will prepare the items carefully and professionally, and try our best to facilitate the sale of your item. Our specialized team will verify submitted items and offer a quotation range. After receiving the items, we will carefully prepare them for sale on our platform.',
    // Howitworks:'How it works',
    // Preliminaryquotation:'Preliminary quotation',
    // font01:'You may provide your item info online and our staff will give you a preliminary quotation range.',
    // Secondaryquotation:'Secondary quotation',
    // font02:'If you are satisfied with the quotation, and you may send your item to Falcons, and we will update the quotation based on the real item.',
    // Listing:'Listing',
    // font03:'If you are still satisfied with the updated quotation, then we will polish the item, take HD pictures, and list the item.',
    // Waystosell:'Ways to sell',
    // font04:'Items are sold under auction mode. Sold price will be decided by the bidding of participants. The bidder who offers higher price within the effective time period will successfully purchase the item.',
    // font05:'Please note that your item will enter a pool of potential lots after being submitted, and will be listed once we find an appropriate auction for it.',
    // Recentbids:'Recently sold',
    // BuyNow:'Buy Now',
    // font06:'Items are listed at fixed-price in the marketplace. Any user can purchase the item and place an order immediately. Your item will be immediately listed after everything is prepared.',
    // font07:'Please note that if the item is not sold after being listed for 1 month, we will automatically reduce the price by 10% every 2 weeks, up to 30% off.',
    // VIPselling:'VIP selling',
    // font08:'Want to sell some precious items with high value? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items. Then you could sit back and relax. We will do everything else for you.',
    // Toapply:'To apply',
    // FAQ:'FAQ',
    // Readytosell:'Ready to sell?',
    // vipWords:' Want to sell some precious items with high value? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items. Then you could sit back and relax. We will do everything else for you. Looking to sell your luxury pieces? Leave your contact number and our VIP concierge team will get in touch with you within one business day to assist and arrange the pickup of your items. Then you could sit back and relax. Let us handle the rest.',
    // Mobile:'Mobile',
    // Pleaseenterthemobilephone:'Please enter the mobile phone',
    // Default:'Default',
    // DeliveryAddress:'Delivery address',
    // ConfirmAddress:'Confirm address',
    // SendToVault:'Send to Falcons vault',
    // SendToVaultContent:'Falcons owns a professional vault with world class security and storage technology to keep your items. If you have not decided where to receive the item, or would like to keep the chance to resell the item, trust us with keeping your purchased items.',
    // WalletContent:'Pay with the balance account in your Falcons Wallet.',
    // PayPal:'PayPal',
    // PayPalContent:'Pay with PayPal e-wallet if you have a PayPal account.',
    // Pay:'Pay',
    // KeepLeastOne:'Please keep at least one set!',
    // ChooseFirst:'Please choose the available shipping method first',
    // FillFormFirst:'Please fill out the form first',
    // Locale:'en_US',
    // onProgress:'On Progress',
    // OrderConfirmed:'Your order is confirmed',
    // OrderConfirmedContent:'Thank you for shopping with us.',
    // Order:'Order',
    // PaymentSuccessful:'Payment successful',
    // PaymentUnsuccessful:'Payment unsuccessful',
    // ContinueShopping:'Continue shopping',
    // ContactUs:'Contact us',
    // PaymentUnsuccessfulContent:'Please update the payment details. Otherwise the order will be canceled after ',
    // Sellyouritem:'Sell your item',
    // Itemdetails:'Item details',
    // Uploadphotos:'Upload photos',
    // sellFont01:'i. You may provide your item info online and our staff will give you an estimated quotation range.',
    // sellFont02:'ii. If you are satisfied with the quotation, and you may send your item to Falcons, and we will update the quotation based on the real item.',
    // sellFont03:'iii. If you are still satisfied with the updated quotation, then we will polish the item, take HD pictures, and list the item. If you would still like to proceed, we will get your item ready for sale and list them online.',
    // Itemlocation:'Item location',
    // localtionFont01:'You may choose your own logistics service provider and deliver the item from your place to us. Please print the packing slip and attach it to the package.',
    // localtionFont02:'If you have purchased from Falcons and stored the item in Falcons vault, you may select from your vaulted collections and list them.',
    // sellmodeFont01:'Items are sold under auction mode. Sold price will be decided by the bidding of participants. The bidder who offers higher price within the effective time period will successfully purchase the item. Please note that your item will enter a pool of potential lots after being submitted, and will be listed once we find an appropriate auction for it.',
    // sellmodeFont02:'Items are listed at fixed-price in the marketplace. Any user can purchase the item and place an order immediately. Your item will be immediately listed after everything is prepared. Please note that if the item is not sold after being listed for 1 month, we will automatically reduce the price by 10% every 2 weeks, up to 30% off.',
    // Selectyouritem:'Select your item',
    // Pleasechooseyouritem:'Please choose your item',
    // uploadText:'To upload an image please click here or use the drag and drop',
    // uploadTitleText:'You can also use the Falcons mobile app to take & upload the item images. Please scan the QR code & follow the instrucitons.',
    // Confirmandsubmit:'Confirm and submit',
    // Whatnext:"What's next?",
    // nextText:'Falcons professional staff will review your submission, verify and grade your item based on the information you provided, and then give you a preliminary quotation range. ',
    // Addanotheritem:'Add another item',
    // Backtohomepage:'Back to homepage',
    // font10:'Should you have any questions, or have not received the quotation after 48 hours, please contact our customer service for more help.',
    // Itemreview:'Item review',
    // Shippingdetails:'Shipping details',
    // Acceptmode:'Accept mode',
    // Keeporiginalmode:'Keep original mode',
    // Rejectreturn:'Reject & return',
    // Shippingwithdelivery:'Shipping with delivery',
    // shippingFont01:'You may choose your own delivery service and send your item to us.',
    // shippingFont02:'If you live in Dubai, you may drop off your item at our warehouse.',
    // FanclonsAddress:'Unit C20 and C21-Feeder Commercial Center-Dubai Production City-Impz-Dubai',
    // DropoffatFalcons:'Drop off at Falcons’ warehouse.',
    // shippingStep01Title:'Step 1: Print packing slip',
    // shippingStep01Text:'Please print the packing slip and attach it to the package.',
    // shippingStep02Title:'Step 2: Send your item',
    // shippingStep02Text:'Enclose the printed packing slip and send it with the item to the address:',
    // shippingStep03Title:'Step 3: Upload tracking number',
    // shippingStep03Text:'After the item is shipped, please attach the tracking number so that we and you can track the delivery process.',
    // Selectyourcourier:'Select your courier',
    // Trackingnumber:'Tracking number',
    // Acceptprice:'Accept price',
    // BacktoSellingItems:'Back to Selling Items',
    // Profilesettings:'Profile settings',
    // UnPaid:'Unpaid',
    // Paid:'Awaiting to progress',
    // PaidContent:'Our team have received your order and are preparing your item.',
    // ShippingInProgress:'Shipping in progress',
    // ShippingInProgressContent:'Your item has been packed and delivered by our cooperated logistics company. It is on the way to your place.',
    // Completed:'Completed',
    // CompletedContent:'You have received your item. The order is completed. Should you have any questions, please feel free to contact our customer support team.',
    // Canceled:'Canceled',
    // Date:'Date',
    // ShippingAmount:'Shipping',
    // ShippingDays:'Shipping days',
    // Mode:'Mode',
    // Items:'Items',
    // PhonePrefix:'Phone Code',
    // ShippingDetail:'Shipping detail',
    // PaymentSuccessfulContent:'Thank you for shopping with us.',
    // Shipping:'Shipping',
    // AddAddress:'Add address',
    // DropOffAtFW:'Drop off at Falcons’ warehouse',
    // IfyouDrop:'If you live in Dubai, you may drop off your item at our warehouse',
    // FWAddress:'Address: Unit C20 and C21-Feeder Commercial Center-Dubai Production City-Impz-Dubai',
    // Withdraw:'Withdraw',
    // Deliverypaymentmethod:'Delivery & payment method',
    // Confirmationpayment:'Confirmation & payment',
    // Withdrawitems:'Withdraw items',
    // DropOff:'Drop off',
    // Purchase:'Purchase',
    // CurrentInCart:'Currently in cart',
    // NotificationCenter:'Notification center',
    // Past:'Past',
    // TrackingNumber:'Tracking number',
    // CanceledContent:'Your order is canceled',
    // contactTitle01:'Get in touch directly',
    // contactText01:'Email: customers{\'@\'}falcons.com',
    // contactText02:'Phone: 800 800 8888 (Mon to Fri 10 am to 4 pm GST)',
    // contactTitle02:'Visit us in person',
    // contactText03:'Our office: Room1001, ABC Tower, DEF Street, Dubai, 12345',
    // contactText04:'Our warehouse: No. 145, XYZ Street, Dubai, 13456',
    // contactText05:'Our auction room: Room 108-110, XXX Plaza, Dubai, 13579',
    // Inquiryform:'Inquiry form',
    // Topic:'Topic',
    // Message:'Message',
    // Submityourmessage:'Submit your message',
    // Ascending:'Ascending',
    // Descending:'Descending',
    // mostpopular:'Most popular',
    // Recentlyadded:'Recently added',
    // Bestcondition:'Best condition',
    // pricelowtohigh:'Price: low to high',
    // pricehightolow:'Price: high to low',
    // Sellingtype:'Selling type',
    // lotnumberascending:'lot number:ascending',
    // lotnumberdescending:'lot number:descending',
    // popularity:'popularity',
    // reservericeascending:'reserve price:ascending',
    // reservericedescending:'reserve price:descending',
    // depositFont01:'Please authorize your credit card for the deposit of',
    // depositFont02:'Why authorize your credit card?',
    // depositFont03:'We are trying to build a clean environment of auctions. We ask every customer to authorize credit card for a deposit to make sure everyone pays for the item they bid for. If you did not bid for anything, or have paid for all your invoices, the authorization will be canceled. But if you fail to pay within time limit, the deposit will be charged.',
    // CreditCard:'Credit card',
    // WithdrawAmount:'Withdraw amount',
    // PayoutMethod:'Payout method',
    // WithdrawContent:'Select a withdrawal method below. Approximate withdrawal time is 48 hours.',
    // BankTransfer:'Bank transfer',
    // channelTypePlaceholder:'Select you preferred payout method',
    // YourBalance:'Your balance',
    // Amount:'Amount',
    // OrderID:'Order ID(if applicable)',
    // AccountholderName:'Account holder name',
    // AccountNumber:'Account number',
    // IbanNumber:'IBAN number',
    // SwiftCode:'Swift code',
    // BankName:'Bank name',
    // BranchName:'Branch name',
    // inputUserName:'Please input your name',
    // inputEmail:'Please input your email',
    // inputMessage:'Please input your message',
    // PaypalAccount:'Paypal account',
    // LimitValidAmount:'Please input valid amount',
    // LimitTwoDecimal:'Please input two decimal places only',
    // ExceedWithdraw:'This exceeds the amount you can withdraw',
    // AskToReturn:'Ask to return',
    // VerifyYourAccount:'Verify your account',
    // VerifyYourAccountContent:'Your access will be limited to Buy Now marketplace and online auctions.',
    // Trackyourparcel:'Track your parcel',
    // Carrier:'Carrier',
    // Shippingupdates:'Shipping updates',
    // Aramex:'Aramex',
    // OrderNumber:'Order number',
    // Photo:'Photo',
    // Verify:'Verify',
    // Popculturecollectibles:'Pop culture collectibles',
    // Sportmemorabilia:'Sport memorabilia',
    // Sporttradingcardcollectibles:'Sport trading card collectibles',
    // Heritagebrands:'Heritage brands',
    // Carscollectibles:'Cars & collectibles',
    // Toyscomics:'Toys & comics',
    // LouisVuitton:'Louis Vuitton',
    // Hermes:'Hermes',
    // Chanel:'Chanel',
    // Dior:'Dior',
    // Gucci:'Gucci',
    // Rolex:'Rolex',
    // Omega:'Omega',
    // TagHeuer:'Tag Heuer',
    // Cartier:'Cartier',
    // Breitling:'Breitling',
    // banedTxt:'Unfortunately, your account is suspended because it violates our user policy. Please contact us for more information.',
    // Refund:'Refund',
    // RefundContent:'Your order had been successfully refunded.',
    // WelcomeTo:'Welcome to',
    // ContinueWithGoogle:'Continue with Google',
    // ContinueWithFacebook:'Continue with Facebook',
    // Or:'or',
    // DownloadInvoice:'Download invoice',
    // Falcons:'Falcons',
    // VipTier:'VIP Tier',
    // Verified:'Verified',
    // Added:'Added',
    // Auction:'Auction',
    // Handbags:'Handbags',
    // Watches:'Watches',
    // From:'From',
    // Add:'Add',
    // Brands:'Brands',
    // Live:'Live',
    // Upcoming:'Upcoming',
    // Ongoing:'Ongoing',
    // ResetYourPassword:'Reset your password',
    // ResetYourPasswordContent:"Please enter an email that you used to register your account with & we'll be sending you a link for reset.",
    // CheckYourInbox:'Check your inbox',
    // CheckYourInboxContentFirst:'A reset link was successfully sent to ',
    // CheckYourInboxContentSecond:'Please check your inbox & follow the instructions given in the email.',
    // ResendLink:'Resend link',
    // SetYourNewPassword:'Set your new password',
    // ConfirmNewPasswordContent:'Your new password has been successfully updated',
    // ReceiveFalconsEmail:"Receive Falcons' promotion emails",
    // EmailPreference:'Email preference',
    // CountryOfResidence:'Country of Residence',
    // ShowDetails:'Show Details',
    // SeeAllNotifications:'See all notifications',
    // Thepricecannotbehigherthantheinitialprice:'The price cannot be higher than the initial price',
    // CodeRule:'Please enter a four digit verification code.',
    // VerificationEmail:'Verify your email',
    // AccountCreatedContent:'To use the full features of the auction you need to verify.',
    // AccountHasbeenCreated:'Account has been created',
    // WeHaveSentCode:"We've sent you a code to ",
    // CheckInboxCode:' to confirm  your email address. Please check your inbox & input it here.',
    // ComingSoon:'Coming Soon!',
    // Estimateprice:'Estimate price',
    // PleaseuploadaFrontandBackPhoto:'Please upload a front and back photo',
    // nextText02:'Should you have any questions, or have not received the quotation after 48 hours, please contact our customer service for more help.',
    // Yes:'Yes',
    // No:'No',
    // Delivery:'Delivery',
    // Payment:'Payment',
    // DeliveryMethod:'Delivery method',
    // Gotopurchase:'Go to purchase',
    // Backtolots:'Back to lots',
    // AddYourPhoneNumber:'Add your phone number',
    // AddYourPhoneNumberContent:'We require customers to verify their phone number to checkout and join auctions. If you don’t verify your phone number, you may still browse our website.',
    // VerifyYourPhoneNumber:'Verify your phone number',
    // VerifyYourPhoneNumberContent:' to verify your phone number. Please check your message & input it here',
    // UploadImgMessage:'Please upload an image format file',
    // UploadPdfMessage:'Please upload an image or PDF format file',
    // EmailSentCode:"We've sent you a code to your email, please check your inbox & input it here",
    // Resend:'Resend',
    // SendtoFalconsvault:'Send to Falcons vault',
    // vaultWords:'Falcons owns a professional vault with world class security and storage technology to keep our items. lf you have not decided where to receive the item, or would like to keep the chance to resell the item, trust us with keeping your purchased items.',
    // UploadSizeMessage:'The file size should be less than 100MB',
    // NewPasswordRule:'Please enter a new password',
    // AddressRule:'Please enter a valid English address',
    // NewEmailRule:'Please enter a new email',
    // SubmitOrder:'Submit order',
    // ReservePrice:'Reserve Price',
    // StartTimeAscending:'StartTime: Ascending',
    // EndTimeAscending:'EndTime: Ascending',
    // Lotclosesat:'Lot closes at:',
    // Withdrawfee:'Withdraw fee',
    // PickUpAtFW:'Pick Up at Falcons’ warehouse',
    // IfyouDropPickUp:'If you live in Dubai, you may pick up your item at our warehouse',
    // SelectProductsFirst:'Please select the product first',
    // 300001:'Invalid email or password. Please try again.',
    // 310002:'This email has been registered.',
    // 310004:'This email has been registered using Google, Facebook or Apple.',
    // 310101:'You cannot change email when you log in with an external account',
    // 330001:'Sorry, you cannot login with this account.',
    // 330002:'Sorry, you have not verified your identity.',
    // 330003:'Sorry, your VIP tier does not meet the criteria',
    // 330005:'Wrong verification code.',
    // 330006:'Sorry, you cannot participate in any auction with this account.',
    // 540306:'You can not make an offer to your own product.',
    // 402006:'You cannot bid your own lots.',
    // 410010:'You need to join the auction first.',
    // 420001:'Sorry, you cannot participate in any auction with this account.',
    // MaxTenAddress:'Add up to 10 addresses',
    // AdditionalinfoText:'Please check our',
    // and:'and',
    // TermsConditions:'Terms & Conditions',
    // FAQs:'FAQs',
    // PhoneRepeat:'Please enter a new phone number',
    // items:'items',
    // FilterBy:'Filter by',
    // submittingText:'Thank you for submitting',
    // maxBidTxt:'If a maximum bid is placed, the platform will then place incremental bids on your behalf, up to a specified maximum amount.',
    // January:'January',
    // February:'February',
    // March:'March',
    // April:'April',
    // May:'May',
    // June:'June',
    // July:'July',
    // August:'August',
    // September:'September',
    // October:'October',
    // November:'November',
    // December:'December',
    // PM:'PM',
    // AM:'AM',
    // Sold:'Sold',
    // BirthDOB:'Date of Birth - D.O.B',
    // Search:'Search',
    // About:'About',
    // Authenticity:'Authenticity',
    // Aboutus:'About us',
    // Contacts:'Contacts',
    // DeliveryPayment:'Delivery & Payment',
    // Other:'Other',
    // PrivacyPolicy:'Privacy Policy',
    // TermsofService:'Terms of Service',
    // Customers:'Customers',
    // Privacy:'Privacy',
    // Categories:'Categories',
    // Mobileapp:'Mobile App',
    // falconsReserved:'Falcon © 2024.All rights reserved.',
    // Pleaseenterthecorrectprice:'Please enter the correct price',
    // mobileTxt:'For better experience, we recommend you to download our App.',
    // Continuewithbrowser:'Continue with browser',
    // ResendCode:'Resend code',
    // ConfirmDeleteItem:'Are you sure delete this item?',
    ConfirmDeleteSelectedItem:'Are you sure delete the selected items',
    CATEGORY:'CATEGORY',
    newCategory:'CATEGORY',
    PRICE:'PRICE',
    GENDER:'GENDER',
    BRANDS:'BRANDS',
    CONDITION:'CONDITION',
    STATUS:'STATUS',
    ESTIMATEPRICE:'ESTIMATE PRICE',
    CURRENTPRICE:'CURRENT PRICE',
    TYPE:'TYPE',
    MYAUCTIONS:'MY AUCTIONS',
    DESCRIPTION:'DESCRIPTION',
    detailTitle01:'International Shipping Available',
    detailText01:'Customs duties and taxes may apply.',
    detailTitle02:'Taxes Not Included',
    detailText02:'VAT and other taxes are not reflected in the listed pricing.',
    detailTitle03:'Authenticity Guaranteed',
    detailText03:'We guarantee the authenticity of this item.',
    Learnmore:'Learn more',
    Readmore:'Read More',
    PRODUCTDETAILS:'Product Details',
    PRODUCTCONDITION:'Product Condition',
    Operate:'Operate',
    DeleteOrder:'Delete Order',
    deleteSuccess:'Delete Order Success!',
    search: 'Search',
    MakeanofferSuccess:'YOU\'VE SUCCESSFULLY MADE A NEW OFFER',
    AmountPaid:'Amount Paid',
    adTitle01:'Paul Newman\'s Daytona',
    adDec01:'Extraordinary craftsmanship from the iconic fashion brand.',
    adButton01:'SHOP THE TIMEPIECE',
    adTitle02:'2000s Saddle handbag',
    adDec02:'Extraordinary craftsmanship from the iconic fashion brand.',
    adButton02:'SHOP THE WALLET',
    adTitle03:'Sell with Falcons',
    adDec03:'Our professional team will help you get the best value of your items.',
    adButton03:'SELL WITH US',
    OrderNo:'Order No.',
    EXPLORE:'Explore our categories',
    noResults: 'No results',
    bybrandTitle: 'By Brand',
    ChangeLanguageAnCurrency:'Change Language & Currency',
    arChangeLanguageAnCurrency:'تغيير اللغة والعملة',
    sConceptReserve:"S-concept {'@'} 2024. All rights reserved.",
    Change:'Change',
    LanguageCurrency:'Language/Currency',
    SaveChanges:'Save Changes',
    Language:'LANGUAGE',
    Currency:'CURRENCY',
    OURWORLD:'OUR WORLD',
    generalTitle:'Curated for the most discerning collectors.',
    theWorldofFalcons:'The World of Falcons',
    UpperBuyNow: 'BUY NOW',
    upperMakeAnOffer: 'MAKE AN OFFER',
    shareProduct: 'SHARE PRODUCT',
    AuthenticationGuarantee:'Our Authentication Guarantee',
    instructionBook:'The first time Audemars Piguet has used this technique, making each dial a unique creation since the distribution of iridescent colours varies from one dial to the next.',
    instructionBookmodel:'Our commitment to authenticity is unwavering. Before any item reaches your hands, it undergoes rigorous scrutiny by our team of expert authenticators. They meticulously verify the genuineness of every piece, ensuring that what you receive is nothing short of exceptional.',
    Estimate_titleVideo:'Audemars Piguet',
    Estimate_titleVideo_nav:'Step into a world of luxury with Falcons’ premier collection of timepieces, where each watch exemplifies timeless prestige.',
    Estimate_two_titleVideo:'Rose Gold Complications',
    Estimate_tw0_titleVideo_nav:'Our dedicated team sources the finest pieces to delight your sophisticated tastes and ensure every moment is exceptional.',
    Latest:'Latest',
    Arrivals:'Arrivals',
    VIEWDETAILS:'VIEW DETAILS',
    isSold:'SOLD',
    VIEWALL:'VIEW ALL',
    newAuthenticity:'Authenticity',
    newVerifyAccount:'Upload Your ID',
    enName:'English',
    arNAME:'العربية',
    aeCurrency:'AED',
    usCurrency:'USD',
    togoRouge:'Birkin 25 Togo Rouge',
    togoRougeList:"This 2024 Birkin bag, crafted from exceptionally soft calf leather, exudes elegance and style. Featuring gold hardware, it is in store-fresh condition, never carried, and comes with all original accessories. Perfect for any discerning fashion connoisseur's collection.",
    newAuction:'AUCTION',
    newSell:'SELL',
    newWishList:'WISHLIST',
    newNOTIFICATIONS:'NOTIFICATIONS',
    newAbout:'About',
    newFAQ:'FAQ',
    Estimate_two_titleVideo_model:'Royal Oak Offshore',
    Estimate_tw0_titleVideo_nav_model:'Epitomizing artistry, heritage & exclusivity.',
    togoRouge_model:'Birkin Togo 25',
    enquire:"ENQUIRE",
    RequestPrice:"Price on Request",
    newLoginText:"Log in {'|'} Register",
    newLogin:'Register',
    Discovermorelink:'DISCOVER THIS ITEM',
    enquireTitle:"THANK YOU FOR SUBMITTING YOUR INQUIRY",
    newSportsCards:'Collectibles',
    mobileEnquireTitle:"Thank you for your inquiry.",
    enquireInfo:"One of our dedicated customer support team  members will contact you shortly.",
    // mobileEnquireInfo:"One of our dedicated customer support team members will contact you shortly.",
    back:"back",
    theSpconetitle:'Nike',
    theSpconetitlelist:'Indulge in an unparalleled shopping experience, where luxury and exclusivity converge.',
    theSpconetitlelink:'SCROLL TO EXPLORE MORE',
    theSpconetitlepage:'Indulge in an unparalleled shopping experience, where luxury and exclusivity converge. Our dedicated team sources the finest pieces to delight your sophisticated tastes and ensure every moment is exceptional.',
    quotationRejected:'Quotation Rejected',
    quotationInfo:'Thank you for your response.A member of our customer service team will be in touch shortly.',
    actiontitle:'Title',
    actiontitleh1:'EXHIBITION REGISTRATION PAGE',
    actiontitlename:'Name',
    actiontitlepnoe:'Primary Contact Number',
    actiontitlephoe:'Secondary Contact Number',
    actiontitleexamet:'Email',
    actiontitledata:'Dates & Time',
    actiontitletime:'Time Slot (GMT+4)',
    actiontitlemer:'Additional Guest',
    actiontitleaout:'You are welcome to invite as many guests as you wish.',
    actiontitleaame:'Guest Name',
    actiontitlemr:'Mr.',
    actiontitlemrs:'Mrs.',
    actiontitlems:'Ms.',
    actiontitlefastname:'First Name',
    actiontitlefastnames:'Last Name',
    verifyTip:'Only verified users can enter the exhibition.',
    auctionDialogTitle:'Continue Your Auction Journey',
    auctionDialogContent:'Please pay the refundable deposit to secure your seat at the auction.',
    // actiontitlefastnexmsa:'Someone@example.com',
    actiontitlefastnexmsatime:'Date',
    actiontitleyes:'Yes',
    actiontitleno:'No',
    actiontitlexiayi:'CONFIRM SLOT',
    actiontitlequxiao:'CANCEL',
    actiontitlenumberyi:'7:00 PM to 8:00 PM',
    actiontitlenumberer:'8:00 PM to 9:00 PM',
    actiontitlenumbersan:'9:00 PM to 10:00 PM',
    actiontitlenumbersi:'10:00 PM to 11:00 PM',
    actiontitlenumberwu:'11:00 PM to 12:00 PM',
    upperEstimate:'ESTIMATE',
    upperReserve:"RESERVE",
    upperViewLots:'VIEW LOTS',
    upperJoinTab:'JOIN OUR EXHIBITION',
    upperAuctinDetail:'AUCTION DETAILS',
    upperCondition:'CONDITIONS OF BUSINESS',
    upperExhibition:'LOG IN TO PICK YOUR SLOT',
    upperSlot:"SLOT",
    auctionTermTab:'Please review the Conditions of Business by clicking the links underlined below:',
    upperLocation:'LOCATION',
    upperDate:'DATE',
    to:'to',
    time:'Time',
    mobileLogin:"REGISTER HERE",
    auctionRegisterText:"REGISTER HERE FOR THE AUCTION",
    auctionFinished:'We anticipate welcoming you to the auction.',
    auctionExhibitLog:'LOG IN TO PICK YOUR EXHIBITION SLOT',
    auctionExhibitSlot:'PICK YOUR EXHIBITION SLOT',
    auctionExhibitBooked:'EXHIBITION SLOT BOOKED',
    gitldetale:'DETAILS HERE',
    gitldetalelist:'Our commitment to authenticity is unwavering. Before any item reaches your hands, it undergoes rigorous scrutiny by our team of expert authenticators. They meticulously verify the genuineness of every piece, ensuring that what you receive is nothing short of exceptional.',
    prolistfz:'Search',
    countryNoData:'Not found',
    PQuotations:'Preliminary Quotations',
    sellModeDesc:'We think your item is more suitable for Auction mode/ Buy mode.\n Would you like to change your selling mode?',
    pickShip:"Please pick your method of shipping",
    Authenticating:'Authenticating',
    pickOtherShip:"Pick another shipping method",
    rejectedDesc:'Our customer service team is reviewing your rejection and will  provide an updated quotation shortly.',
    shipService:'As part of our white glove service, one of our trusted chauffeurs will pick up your piece for you.',
    shipByfalcons:'Pick up by Falcons',
    sellerDetails:'Seller Details',
    updateQuotation:'Updated Quotation',
    listingPrice:'Listing Price',
    shipedByfalcons:'Pick up by Falcons 丨 48 Hours',
    rejectQuotatonDesc:"Our customer service team is reviewing your rejection and will provide an updated quotation shortly.",
    shipByfalconsInfo:'One of our trusted chauffeurs will personally collect your item for you.',
    dropAtfalcons:'Drop off at Falcons',
    dropAtfalconsInfo:'We welcome you to visit to drop off your item and explore our selection of luxury items.',
    GoogleMap:'Google Map Location',
    sellfromone:'In My Possession',
    sellfromtwo:'Falcons vault',
    sellfromlistone:'If an item is with you, we will be in touch with you for delivery to the Falcons warehouse.',
    sellfromlisttwo:'If you have made a purchase from Falcons and stored the item in our vault, you may select from your vaulted collections and list them.',
    seellfromnoe:'Sell',
    seellfromlistnoe:'Items are listed at a fixed price in the marketplace. Any user can purchase the item and place an order immediately.',
    seellfromtwo:'Auction',
    seellfromlisttwo:'Sold price will be decided by the bidding of participants. The bidder who offers a higher price within the effective time period will purchase the item.',
    selltitle:'Item Location',
    selltitkebro:'Selling Mode',
    selllistsch:'Select your item',
    sellMale:'Please select gender.',
    newVerifyUploadTip:'Please provide clear, unobstructed photos of the document.The name on the document must match the registered name.',
    sellRolex:'Please select a brand.',
    sellTimepiece:'Please select a category.',
    sellPleasetypehere:'Please type here.',
    sellTimepieces:'Please select accessories.',
    sellyes:'Please select yes or no.',
    sellPleasetypeheresde:'Please type here.',
    sellPrelovedndeo:'Describe your items in few sentences',
    sellCategory:'Gender',
    sellBrand:'Brand',
    sellProducttype:'Category',
    sellthreecont:'Accessories (such as straps, manuals eg.)',
    sellthreenode:'If Other, Please type here.',
    sellOtherIdesacpt:'Description',
    sellSelect:'Select your item',
    sellPreloved:'Please select the condition.'
}

export default en
