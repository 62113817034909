import i18n from '@/i18n'
const errorMsg = {
    300001:i18n.global.t('300001'),
    310002:i18n.global.t('310002'),
    310004:i18n.global.t('310004'),
    310101:i18n.global.t('310101'),
    330001:i18n.global.t('330001'),
    // 330002:i18n.global.t('330002'),
    330003:i18n.global.t('330003'),
    330005:i18n.global.t('330005'),
    330006:i18n.global.t('330006'),
    540306:i18n.global.t('540306'),
    402006:i18n.global.t('402006'),
    410010:i18n.global.t('410010'),
    420001:i18n.global.t('420001'),
    310013:i18n.global.t('310013'),
}
export default errorMsg