import { createI18n } from 'vue-i18n'
import en from '@/i18n/langs/en.js'
import ar from '@/i18n/langs/ar.js'
import plugins from "@/plugins/plugins";
const messages = {
    en,
    ar
}

const i18n = createI18n({
    legacy: false,         // 使用 Composition API 模式，则需要将其设置为false
    globalInjection: true, //全局生效$t
    locale:  plugins.getLocal('lang'),          // 默认cn翻译 or localStorage.getItem("lang") || "cn",
    fallbackLocale: plugins.getLocal('lang'),
    messages
})
setI18nLanguage(plugins.getLocal('lang'))
export function setI18nLanguage (lang) {
    // i18n.locale = lang;
    let dom=document.querySelector('html');
    dom.setAttribute('lang', lang);
    if (lang === 'ar') {
        dom.setAttribute('dir', 'rtl');
        dom.style.setProperty('--font-family-type','Tajawal')
    } else {
        dom.removeAttribute('dir')
        dom.style.setProperty('--font-family-type','Andreas')
    }
    // return lang;
}

export function loadLanguageAsync (lang) {
    i18n.global.locale.value = lang
    setI18nLanguage(lang);
    // if (i18n.global.locale.value !== lang) {
    //   if (!loadedLanguages.includes(lang)) {
    //     return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.js`)
    //       .then(msgs => {
    //         if (msgs.default) {
    //           i18n.setLocaleMessage(lang, msgs.default[lang]);
    //           loadedLanguages.push(lang);
    //           return setI18nLanguage(lang);
    //         }
    //       })
    //       .catch(() => console.log('import loaded failed000'));
    //   }
    //   return Promise.resolve(setI18nLanguage(lang));
    // }
    // return Promise.resolve();
}

export default i18n;
