class plugins {
    setLocal(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }
    getLocal(key) {
        const value = window.localStorage.getItem(key)
        if(value === "undefined"){ return }
        if (value) {
            return JSON.parse(value)
        }
    }
    deleteLocal(key) {
        window.localStorage.removeItem(key)
    }
    setSession(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    }
    getSession(key) {
        const value = window.sessionStorage.getItem(key)
        if(value === "undefined"){ return }
            if (value) {
            return JSON.parse(value)
        }
    }
    deleteSession(key) {
        window.sessionStorage.removeItem(key)
    }
    removeSellInfo() {
         window.localStorage.removeItem('itemDetails');
         window.localStorage.removeItem('ProofAuthenticity');
         window.localStorage.removeItem('sellDetail');
         window.localStorage.removeItem('sellPhotoUpload');
    }
}
export default new plugins